import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Image from "../base/image"

// CATEGORY LIST
const CategoryListContainer = styled(
  /**
   * Category List
   *
   * @param {{
   *   stacked?: boolean;
   *   className?: string;
   * }} props
   */
  ({ stacked, ...props }) => (
    <div
      {...props}
      className={(stacked ? "isStacked" : "") + " " + props.className}
    />
  )
)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 10px;
  overflow: auto;
  scrollbar-width: none; // hide Firefox scrollbars

  &.isStacked {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -30px;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    padding: 20px;
  }
`

const CategoryListItem = styled.div`
  min-width: 100px;
  padding: 10px;
  flex-shrink: 0;

  div${CategoryListContainer}.isStacked & {
    min-width: auto;
    width: 50%;
  }

  ${({ theme }) => theme.bp.desktop} {
    min-width: 180px;

    div${CategoryListContainer}.isStacked & {
      width: 33.333%;
    }
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    div${CategoryListContainer}.isStacked & {
      width: 16.666%;
    }
  }
`

const CategoryCard = styled(Link)`
  flex-shrink: 0;
  display: block;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.darkGreen};

  div${CategoryListContainer}:not(.isStacked) & {
    width: 100px;
    ${({ theme }) => theme.bp.desktop} {
      width: 170px;
    }
  }
`

const CategoryCardImage = styled(Image)`
  display: block;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 10px;
  border: 2px solid transparent;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  transition: border-color 0.2s ease-in-out;

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  img {
    border-radius: 50%;
  }

  a${CategoryCard}[aria-current="page"] & {
    border-color: ${({ theme }) => theme.colors.darkGreen};
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    margin-bottom: 30px;
  }
`

const CategoryCardNoImage = styled(Image)`
  display: block;
  height: 0;
  background-color: #ccc;
  padding-bottom: 100%;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid transparent;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  transition: border-color 0.2s ease-in-out;

  a${CategoryCard}[aria-current="page"] & {
    border-color: ${({ theme }) => theme.colors.darkGreen};
  }

  &.more {
    position: relative;
    &::before,
    &:after {
      content: "";
      display: block;
      background-color: ${({ theme }) => theme.colors.darkGreen};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::before {
      width: 3px;
      height: 40px;
    }
    &::after {
      width: 40px;
      height: 3px;
    }
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    margin-bottom: 30px;
  }
`

const CategoryCardTitle = styled.h5`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  line-height: 1.5;
  font-weight: normal;
  word-wrap: normal;
  letter-spacing: 0.06em;

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[4]}px;

    div${CategoryListContainer}:not(.isStacked) & {
      font-size: ${({ theme }) => theme.fontSizes[3]}px;
    }
  }
`

const CategoryList = ({ categories, stacked, showMore }) => {
  const [isShownMore, setIsShownMore] = useState(false)
  return (
    <CategoryListContainer stacked={stacked}>
      {(categories || []).map((e) => {
        return (
          <CategoryListItem>
            <CategoryCard to={`../${e.slug}`}>
              {e.thumbnail && (
                <CategoryCardImage primary={e.thumbnail} ratio={1 / 1} />
              )}
              {!e.thumbnail && <CategoryCardNoImage></CategoryCardNoImage>}
              <CategoryCardTitle>{e.name}</CategoryCardTitle>
            </CategoryCard>
          </CategoryListItem>
        )
      })}

      {showMore && !isShownMore && (
        <CategoryListItem
          onClick={() => {
            showMore()
            setIsShownMore(true)
          }}
        >
          <CategoryCard>
            <CategoryCardNoImage className="more"></CategoryCardNoImage>
            <CategoryCardTitle>{"Show more"}</CategoryCardTitle>
          </CategoryCard>
        </CategoryListItem>
      )}
    </CategoryListContainer>
  )
}

export default CategoryList
