import * as React from "react"
import styled from "@emotion/styled"
import Medusa from "../../services/api"
import useNotifications from "../../hooks/useNotifications"
import { NotificationType } from "../notifications"

export const NewsletterPopup = ({ isVisible, ...props }) => {
  const [visible, setVisible] = React.useState(isVisible)
  const { pushNotification } = useNotifications()
  const [email, setEmail] = React.useState("")

  const handleClose = () => {
    setVisible(false)
    localStorage.setItem("newsletterPopupClosed", "true")
  }

  const handleSubscribe = () => {
    Medusa.newsletter
      .signup({ email: email, ids: {} })
      .then(({ data }) => {
        pushNotification({
          id: "newsletter-signed-up",
          message: "Successfully signed up",
          type: NotificationType.success,
        })
        setVisible(false)
        localStorage.setItem("newsletterPopupClosed", "true")
        return data
      })
      .catch((err) => console.error(err))
  }

  React.useEffect(() => {
    const isPopupClosed = localStorage.getItem("newsletterPopupClosed")

    if (isPopupClosed === "true") {
      setVisible(false)
    }
  }, [])

  if (!visible) return null

  return (
    <UiNewsletterPopup {...props} isVisible={visible}>
      <UiNewsletterText>Sign up to our newsletter and get</UiNewsletterText>
      <UiNewsletterDiscountText>
        10<span>%</span>Off
      </UiNewsletterDiscountText>
      <UiNewsletterText>ON your next order</UiNewsletterText>
      <UiNewsletterInput
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <UiNewsletterButtonPrimary onClick={handleSubscribe}>
        Sign me up
      </UiNewsletterButtonPrimary>
      <UiNewsletterButtonSecondary onClick={handleClose}>
        Nah, I&apos;m good
      </UiNewsletterButtonSecondary>
    </UiNewsletterPopup>
  )
}

const UiNewsletterPopup = styled.div`
  max-width: 340px;
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.yellow};
  border-radius: 20px;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  transition: opacity 0.2, visibility 0.2s;
  padding-block-start: 34px;
  padding-block-end: 26px;
  padding-inline: 20px;
  margin: 10px;
`

const UiNewsletterText = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-block-end: 4px;
`

const UiNewsletterDiscountText = styled.p`
  font-size: 52px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -2px;
  margin-block-end: 0;

  span {
    display: inline-block;
    font-size: 48px;
    margin-inline-end: 10px;
  }
`

const UiNewsletterInput = styled.input`
  color: ${({ theme }) => theme.colors.darkGreen};
  font-weight: 700;
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 9999px;
  padding-inline: 28px;
  margin-block-end: 10px;
  outline: 0;
  margin-block-start: 18px;
  text-align: center;

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkGreen};
    font-weight: 700;
    opacity: 1;
  }
`

const UiNewsletterButtonPrimary = styled.button`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  line-height: 48px;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.darkGreen};
  border: 0;
  border-radius: 9999px;
  cursor: pointer;
  margin-block-end: 18px;
`

const UiNewsletterButtonSecondary = styled.button`
  color: ${({ theme }) => theme.colors.darkGreen};
  line-height: 0.8;
  font-weight: 700;
  background-color: transparent;
  border: 0;
  border-block-end: 2px solid currentColor;
  padding: 0;
  cursor: pointer;
`
